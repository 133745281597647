import { useEffect, useState, useRef, useMemo } from 'react';
import './App.scss';
import { Chart } from '@antv/g2';
import ReactSwipe from 'react-swipe';

const getData = async () => {
  return fetch('/data').then(res => res.json());
}

const teamsInfo = [
  {
    name: '蔷薇组',
    members: {
      month_2: ['金凤', '张莉', '周丽'],
      month_3: ['金凤', '张莉', '周丽', '经晶', '钱鑫', '王小爱'],
      month_4: ['金凤', '张莉', '周丽', '经晶', '钱鑫', '王小爱'],
      month_5: ['金凤', '周丽', '经晶', '钱鑫']
    },
    color: '#fff'
  },
  {
    name: '猛虎组',
    members: {
      month_2: ['姚天天', '朱凤艳', '王惠', '朱婷婷'],
      month_3: ['姚天天', '朱婷婷', '程燕', '张星云', '钱颖', '钟惠莉'],
      month_4: ['姚天天', '朱婷婷', '程燕', '张星云', '钱颖', '钟惠莉', '朱贝贝'],
      month_5: ['姚天天', '朱婷婷', '程燕', '张星云', '钱颖', '钟惠莉', '朱贝贝']
    },
    color: '#ff8128'
  },
  {
    name: '乘风组',
    members: {
      month_2: ['卢婷', '甘晶晶', '程晓晓'],
      month_3: ['卢婷', '甘晶晶', '张玲兰', '陈雯雯', '汪云', '朱晓雅'],
      month_4: ['卢婷', '甘晶晶', '张玲兰', '陈雯雯', '汪云', '朱晓雅', '蒋孟巧'],
      month_5: ['卢婷', '张玲兰', '陈雯雯', '汪云', '朱晓雅', '蒋孟巧'],
    },
    color: '#59ffd1'
  }
];

const notIn_2 = ['周丽', '程晓晓', '王惠', '朱婷婷'];

const notIn_3 = ['张玲兰', '陈雯雯', '程燕', '张星云', '钱鑫', '王小爱', '钱颖', '钟惠莉', '汪云', '朱晓雅'];

const notIn_4 = ['王小爱', '张莉', '甘晶晶'];

const notIn_5 = [];

function App() {

  const barChart = useRef(null);

  const barChartOrder = useRef(null);

  const barChartWeek = useRef(null);

  const barChartOrderWeek = useRef(null);

  const reactSwipeEl = useRef(null);

  // const [barData, setBarData] = useState(JSON.parse(dataString));
  const [barData, setBarData] = useState([]);

  const [barDataOrder, setBarDataOrder] = useState();

  const [barDataWeek, setBarDataWeek] = useState([]);

  const [barDataOrderWeek, setBarDataOrderWeek] = useState();

  const [ratio, setRatio] = useState(((document.documentElement.clientWidth || document.body.clientWidth) / 1570).toFixed(2));

  const timer = useRef(null);

  const [swipeIndex, setSwipeIndex] = useState(0);

  const [slideAuto, setSlideAuto] = useState(30000);

  const [monthData, setMonthData] = useState({
    rankPersonal: [],
    rankTeam: []
  });

  useEffect(() => {

    // console.log(JSON.parse(dataString));

    const handler = () => {
      let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

      let ratio = (clientWidth / 1570).toFixed(2);

      setRatio(ratio);

      document.getElementsByTagName('html')[0].style.fontSize = Math.ceil(10 * ratio) + 'px';

    };

    window.addEventListener('resize', handler);

    handler();

    !timer.current && getDataEveryMin();

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  useEffect(() => {
    if (barData && barData.length) {
      const chartEl = document.getElementById('chart');

      let _data = [];

      barData.forEach(it => {
        let index = _data.findIndex(_item => _item.real_name === it.real_name);

        if (index > -1) {
          _data.splice(index, 1, {
            ..._data[index],
            number: _data[index].number + it.number
          });
        } else {
          _data.push(it);
        }
      });

      _data = _data.sort((a, b) => a.number - b.number);

      const _avaerage = Math.floor(_data.reduce((total, item) => total + item.number, 0) / _data.length);

      setTimeout(() => {
        !barChart.current && (barChart.current = new Chart({
          container: 'chart',
          width: chartEl.clientWidth,
          height: chartEl.clientHeight,
          padding: [15, 45, 50, 100]
        }));

        barChart.current.data(_data);

        barChart.current.axis('real_name', {
          label: {
            style: (val) => {
              const _team = teamsInfo.find(it => it.members.month_5.find(_item => _item === val));

              return {
                fontSize: 18,
                fill: _team ? _team.color : 'rgba(255, 255, 255, 0.8)',
                fontWeight: 'bold',
              }
            },
          },

        });

        barChart.current.axis('number', {
          label: {
            formatter: (val) => {
              return val + ' 个';
            },
            style: {
              fontSize: 18,
              fill: 'rgba(255, 255, 255, 0.75)',
              fontWeight: 'bold',
            },
          },

        });

        barChart.current.interval().position('real_name*number').color('number', (xValue) => xValue >= _avaerage ? '#2bb7ff' : '#fffc00').size(26).label('number', (xValue) => ({
          content: xValue + '个',
          style: {
            fontSize: 16,
            fill: 'rgba(255, 255, 255, 0.75)',
            fontWeight: 'bold',
          },

          offset: 5,
        })).tooltip({
          fields: ['number']
        });

        barChart.current.scale('number', {
          alias: '数量',
        });

        barChart.current.legend(false);

        barChart.current.coordinate().transpose();

        barChart.current.interaction('active-region');

        barChart.current.render();

      }, 0);
    } else {
      if (barChart.current) {
        barChart.current.data([]);
        barChart.current.render();
      }

    }
  }, [barData]);


  useEffect(() => {
    if (barDataOrder && barDataOrder.length) {
      const chartEl = document.getElementById('chart-order');

      let _data = barDataOrder.reverse().filter(it => it.employee !== '马婷');

      console.log(barDataOrder);

      const _avaerage = Math.floor(_data.reduce((total, item) => total + item.count, 0) / _data.length);

      setTimeout(() => {
        !barChartOrder.current && (barChartOrder.current = new Chart({
          container: 'chart-order',
          width: chartEl.clientWidth,
          height: chartEl.clientHeight,
          padding: [15, 45, 50, 100]
        }));

        barChartOrder.current.data(_data);

        barChartOrder.current.axis('employee', {
          label: {
            style: (val) => {
              const _team = teamsInfo.find(it => it.members.month_5.find(_item => _item === val));

              return {
                fontSize: 18,
                fill: _team ? _team.color : 'rgba(255, 255, 255, 0.8)',
                fontWeight: 'bold',
              }
            },

          },

        });

        barChartOrder.current.axis('count', {
          label: {
            formatter: (val) => {
              return val + ' 单';
            },
            style: {
              fontSize: 18,
              fill: 'rgba(255, 255, 255, 0.75)',
              fontWeight: 'bold',
            },
          },

        });

        barChartOrder.current.interval().position('employee*count').color('count', (xValue) => xValue >= _avaerage ? '#2bb7ff' : '#fffc00').size(26).label('count', (xValue) => ({
          content: xValue + '单',
          style: {
            fontSize: 16,
            fill: 'rgba(255, 255, 255, 0.75)',
            fontWeight: 'bold',
          },

          offset: 5,
        })).tooltip({
          fields: ['count']
        });

        barChartOrder.current.scale('count', {
          alias: '数量',
        });

        barChartOrder.current.legend(false);

        barChartOrder.current.coordinate().transpose();

        barChartOrder.current.interaction('active-region');

        barChartOrder.current.render();

      }, 0);
    } else {
      if (barChartOrder.current) {
        barChartOrder.current.data([]);
        barChartOrder.current.render();
      }

    }
  }, [barDataOrder]);

  useEffect(() => {
    if (barDataWeek && barDataWeek.length) {
      const chartEl = document.getElementById('chart-week');

      let _data = [];

      barDataWeek.forEach(it => {
        let index = _data.findIndex(_item => _item.real_name === it.real_name);

        if (index > -1) {
          _data.splice(index, 1, {
            ..._data[index],
            number: _data[index].number + it.number
          });
        } else {
          _data.push(it);
        }
      });

      _data = _data.sort((a, b) => a.number - b.number);

      const _avaerage = Math.floor(_data.reduce((total, item) => total + item.number, 0) / _data.length);

      setTimeout(() => {
        !barChartWeek.current && (barChartWeek.current = new Chart({
          container: 'chart-week',
          width: chartEl.clientWidth,
          height: chartEl.clientHeight,
          padding: [15, 45, 50, 100]
        }));

        barChartWeek.current.data(_data);

        barChartWeek.current.axis('real_name', {
          label: {
            style: (val) => {
              const _team = teamsInfo.find(it => it.members.month_5.find(_item => _item === val));

              return {
                fontSize: 18,
                fill: _team ? _team.color : 'rgba(255, 255, 255, 0.8)',
                fontWeight: 'bold',
              }
            },
          },

        });

        barChartWeek.current.axis('number', {
          label: {
            formatter: (val) => {
              return val + ' 个';
            },
            style: {
              fontSize: 18,
              fill: 'rgba(255, 255, 255, 0.75)',
              fontWeight: 'bold',
            },
          },

        });

        barChartWeek.current.interval().position('real_name*number').color('number', (xValue) => xValue >= _avaerage ? '#1fd641' : '#ff0000').size(26).label('number', (xValue) => ({
          content: xValue + '个',
          style: {
            fontSize: 16,
            fill: 'rgba(255, 255, 255, 0.75)',
            fontWeight: 'bold',
          },

          offset: 5,
        })).tooltip({
          fields: ['number']
        });

        barChartWeek.current.scale('number', {
          alias: '数量',
        });

        barChartWeek.current.legend(false);

        barChartWeek.current.coordinate().transpose();

        barChartWeek.current.interaction('active-region');

        barChartWeek.current.render();

      }, 0);
    } else {
      if (barChartWeek.current) {
        barChartWeek.current.data([]);
        barChartWeek.current.render();
      }

    }
  }, [barDataWeek]);


  useEffect(() => {
    if (barDataOrderWeek && barDataOrderWeek.length) {

      const chartEl = document.getElementById('chart-order-week');

      let _data = barDataOrderWeek.reverse().filter(it => it.employee !== '马婷');

      const _avaerage = Math.floor(_data.reduce((total, item) => total + item.count, 0) / _data.length);

      setTimeout(() => {
        !barChartOrderWeek.current && (barChartOrderWeek.current = new Chart({
          container: 'chart-order-week',
          width: chartEl.clientWidth,
          height: chartEl.clientHeight,
          padding: [15, 45, 50, 100]
        }));

        barChartOrderWeek.current.data(_data);

        barChartOrderWeek.current.axis('employee', {
          label: {
            style: (val) => {
              const _team = teamsInfo.find(it => it.members.month_5.find(_item => _item === val));

              return {
                fontSize: 18,
                fill: _team ? _team.color : 'rgba(255, 255, 255, 0.8)',
                fontWeight: 'bold',
              }
            },
          },

        });

        barChartOrderWeek.current.axis('count', {
          label: {
            formatter: (val) => {
              return val + ' 单';
            },
            style: {
              fontSize: 18,
              fill: 'rgba(255, 255, 255, 0.75)',
              fontWeight: 'bold',
            },
          },

        });

        barChartOrderWeek.current.interval().position('employee*count').color('count', (xValue) => xValue >= _avaerage ? '#1fd641' : '#ff0000').size(26).label('count', (xValue) => ({
          content: xValue + '单',
          style: {
            fontSize: 16,
            fill: 'rgba(255, 255, 255, 0.75)',
            fontWeight: 'bold',
          },

          offset: 5,
        })).tooltip({
          fields: ['count']
        });

        barChartOrderWeek.current.scale('count', {
          alias: '数量',
        });

        barChartOrderWeek.current.legend(false);

        barChartOrderWeek.current.coordinate().transpose();

        barChartOrderWeek.current.interaction('active-region');

        barChartOrderWeek.current.render();

      }, 0);
    } else {
      if (barChartOrderWeek.current) {
        barChartOrderWeek.current.data([]);
        barChartOrderWeek.current.render();
      }

    }
  }, [barDataOrderWeek]);

  const getDataEveryMin = async () => {

    try {
      const res = await getData();

      if (res.success) {
        setBarData(res.data.day.addWexin);

        const orderData = [...teamsInfo[0].members.month_5, ...teamsInfo[1].members.month_5, ...teamsInfo[2].members.month_5].map(member => {
          const item = (res.data.day.orderCount || []).find(_it => _it.employee === member);

          if (item) {
            return item;
          } else {
            return {
              count: 0,
              employee: member
            }
          }
        });

        setBarDataOrder(orderData.sort((a, b) => b.count - a.count));

        setBarDataWeek(res.data.week.addWexin);

        const orderDataWeek = [...teamsInfo[0].members.month_5, ...teamsInfo[1].members.month_5, ...teamsInfo[2].members.month_5].map(member => {
          const item = (res.data.week.orderCount || []).find(_it => _it.employee === member);

          if (item) {
            return item;
          } else {
            return {
              count: 0,
              employee: member
            }
          }
        });

        setBarDataOrderWeek(orderDataWeek.sort((a, b) => b.count - a.count));

        let _data = {
          rankPersonal: [],
          rankTeam: [],
          addWexin: [],
        };

        const _monthOrderCount = res.data.month.orderCount;

        // const _orderMonth2 = res.data.month2;

        // const _orderMonth3 = res.data.month3;

        const _orderMonth4 = res.data.month4;
        const _orderMonth5 = res.data.month5;

        if (res.data.month.addWexin && res.data.month.addWexin.length) {
          let _arr = [];

          res.data.month.addWexin.forEach(it => {
            let index = _arr.findIndex(_item => _item.real_name === it.real_name);

            if (index > -1) {
              _arr.splice(index, 1, {
                ..._arr[index],
                number: _arr[index].number + it.number
              });
            } else {
              _arr.push(it);
            }
          });

          _arr = _arr.sort((a, b) => a.number - b.number);

          _data.addWexin = _arr;

        }

        // {
        //   if (a.number - b.number) {
        //     return a.number - b.number
        //   }
        // }

        if (_monthOrderCount && _monthOrderCount.length) {

          _data.rankPersonal = _monthOrderCount.filter(it => it.employee !== '马婷').map(it => ({
            ...it,
            addWexin: (_data.addWexin.find(item => item.real_name === it.employee) || { number: 0 }).number
          }));

          _data.rankPersonal.sort((a, b) => {
            if (b.count - a.count) {
              return b.count - a.count;
            } else {
              return b.addWexin - a.addWexin;
            }
          })

          let teams = [];

          teamsInfo.forEach(team => {
            let _total = 0;

            let _memCount = 0;

            let _totalOld = 0;

            // let _totalMonth2 = 0;

            // let _totalMonth3 = 0;
            let _totalMonth4 = 0;
            let _totalMonth5 = 0;

            // let _memMonth2 = 0;

            // let _memMonth3 = 0;
            let _memMonth4 = 0;
            let _memMonth5 = 0;

            // team.members.month_2.forEach(_mem => {
            //   let _countMonth2 = _orderMonth2.find(it => it.employee === _mem) ? (_orderMonth2.find(it => it.employee === _mem).count || 0) : 0;

            //   _total += _countMonth2;

            //   if (!notIn_2.find(it => it === _mem)) {
            //     // _memCount = _memCount + 1;
            //     _memMonth2 = _memMonth2 + 1;
            //     // _totalOld +=  _countMonth2;

            //     _totalMonth2 += _countMonth2;
            //   }
            // });

            // team.members.month_4.forEach(_mem => {
            //   let _countMonth3 = _orderMonth3.find(it => it.employee === _mem) ? (_orderMonth3.find(it => it.employee === _mem).count || 0) : 0;

            //   _total += _countMonth3;

            //   if (!notIn_3.find(it => it === _mem)) {
            //     // _memCount = _memCount + 1;
            //     _memMonth3 = _memMonth3 + 1;
            //     // _totalOld +=  _countMonth3;
            //     _totalMonth3 += _countMonth3;
            //   }
            // });

            team.members.month_4.forEach(_mem => {
              let _countMonth4 = _orderMonth4.find(it => it.employee === _mem) ? (_orderMonth4.find(it => it.employee === _mem).count || 0) : 0;

              _total += _countMonth4;

              if (!notIn_4.find(it => it === _mem)) {
                // _memCount = _memCount + 1;
                _memMonth4 = _memMonth4 + 1;
                // _totalOld +=  _countMonth3;
                _totalMonth4 += _countMonth4;
              }
            });

            team.members.month_5.forEach(_mem => {
              let _countMonth5 = _orderMonth5.find(it => it.employee === _mem) ? (_orderMonth5.find(it => it.employee === _mem).count || 0) : 0;

              _total += _countMonth5;

              if (!notIn_5.find(it => it === _mem)) {
                // _memCount = _memCount + 1;
                _memMonth5 = _memMonth5 + 1;
                // _totalOld +=  _countMonth3;
                _totalMonth5 += _countMonth5;
              }
            });

            teams.push({
              ...team,
              total: _total,
              // averageMonth2: (_totalMonth2 / _memMonth2).toFixed(1),
              // averageMonth3: (_totalMonth3 / _memMonth3).toFixed(1),
              averageMonth4: (_totalMonth4 / _memMonth4).toFixed(1),
              averageMonth5: (_totalMonth5 / _memMonth5).toFixed(1),
              averageSeason: (parseFloat((_totalMonth4 / _memMonth4).toFixed(1)) + parseFloat((_totalMonth5 / _memMonth5).toFixed(1))).toFixed(1),
              // averageSeason: (parseFloat((_totalMonth2 / _memMonth2).toFixed(1)) + parseFloat((_totalMonth3 / _memMonth3).toFixed(1))).toFixed(1)
            });
          });

          teams.sort((a, b) => b.averageSeason - a.averageSeason);

          _data.rankTeam = teams;

          setMonthData(_data);

        }

        // res.data.month.orderCount
        // setMonthData(res.data.month.orderCount);
      }
    } catch (e) {
      console.log(e);
    }

    timer.current = setTimeout(() => {
      getDataEveryMin();
    }, 60000);
  };

  const swipeChange = (index) => {
    // console.log(index);

    setSwipeIndex(index);
  };

  const swipeOptions = useMemo(() => ({
    startSlide: swipeIndex,
    continuous: true,
    speed: 600,
    auto: 30000,
    callback: swipeChange
  }), []);

  return (
    <div className="main">
      <div className="panel-title-wrap" >
        <div className={`panel-title${swipeIndex === 0 ? ' active' : ''}`} onClick={() => {
          if (swipeIndex !== 0) {
            reactSwipeEl.current.slide(0);
          }

          setTimeout(() => {
            window.location.reload();
          }, 300000);

        }}>今天</div>

        <div className={`panel-title${swipeIndex === 1 ? ' active' : ''}`} onClick={() => {
          if (swipeIndex !== 1) {
            reactSwipeEl.current.slide(1);
          }

          setTimeout(() => {
            window.location.reload();
          }, 300000);
        }}>上周</div>

        <div className={`panel-title${swipeIndex === 2 ? ' active' : ''}`} onClick={() => {
          if (swipeIndex !== 2) {
            reactSwipeEl.current.slide(2);
          }

          setTimeout(() => {
            window.location.reload();
          }, 300000);
        }}>当月</div>
      </div>

      <ReactSwipe
        className="carousel"
        swipeOptions={swipeOptions}
        ref={reactSwipeEl}
      >
        <div className="screen">
          <div className="chart-container">
            <div className="chart-title">
              +V 数量
            </div>
            <div className="chart" id="chart">
            </div>
          </div>

          <div className="chart-container right">
            <div className="chart-title">
              订单数量
            </div>
            <div className="chart" id="chart-order">
            </div>
          </div>
        </div>

        <div className="screen">
          <div className="chart-container">
            <div className="chart-title">
              +V 数量
            </div>
            <div className="chart" id="chart-week">
            </div>
          </div>

          <div className="chart-container right">
            <div className="chart-title">
              订单数量
            </div>
            <div className="chart" id="chart-order-week">
            </div>
          </div>
        </div>

        <div className="screen">
          <div className="sale-list">
            <div className="sale-title">
              订单与+V 数量
            </div>

            {
              (monthData.rankPersonal || []).map((it, index) => <div key={it.employee} className="sale-item">
                <div className={`icon icon-${['gold', 'siliver', 'bronze'][index]} || ''`}></div>
                <div className="desc">
                  <div>{it.employee}</div>
                </div>

                {
                  it.count ? <div className="tip">{`${it.count}单${it.addWexin ? ` / ${it.addWexin}个` : ''}`}</div> : null
                }

              </div>)
            }

          </div>
          <div className="team-list">
            <div className="team-list-title">
              小组排名（季度）
            </div>

            {
              monthData.rankTeam.map(team => <div className={`team-item team-${{ '蔷薇组': 'qiangwei', '猛虎组': 'menghu', '乘风组': 'chengfeng' }[team.name]}`}>
                <div className="team-title" style={{ color: team.color }}>
                  {team.name}

                  &nbsp;

                  <div className="tip spec">共{team.total}单</div>

                  <div className="tip">{team.averageMonth4}单+{team.averageMonth5}单={team.averageSeason}单</div>

                  {/* <div className="tip">{team.averageMonth4}单</div> */}

                </div>

                <div className="team-content">
                  {
                    team.members.month_5.map((mem, index) => <div className="blk" key={index}>{mem}</div>)
                  }
                </div>
              </div>)
            }
          </div>
        </div>
      </ReactSwipe>
      {/* <div className="screen">
        <div className="panel-title">今天</div>
        <div className="chart-container">
          <div className="chart-title">
            +V 数量
          </div>
          <div className="chart" id="chart">
          </div>
        </div>

        <div className="chart-container right">
          <div className="chart-title">
            订单数量
          </div>
          <div className="chart" id="chart-order">
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default App;
